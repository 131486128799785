import React from "react";
import map from "lodash/map";
import { FlippingImages } from "components/FlippingImages";
import { SaveBadge } from "components/SaveBadge";
import { VariantAnchor } from "components/VariantAnchor";
import { VariantPrice } from "components/VariantPrice";
import { defaultVariant } from "@lana-commerce/core/defaultVariant";
import { useSiteData } from "utils/useSiteData";
import { variantImages } from "@lana-commerce/core/variantImages";

import { T } from "./BestSellers.tlocale";

export const BestSellers = () => {
  const {
    shop,
    currencies,
    bestSellers: { category, products },
  } = useSiteData();
  if (!category) return null;
  return (
    <>
      <div className="page-with-menu__header">{T("Best sellers")}</div>
      <div className="product-items">
        {map(products, (product) => {
          const variant = defaultVariant(product);
          if (!variant) return null;
          const images = variantImages(variant);
          return (
            <div className="product-items__item" key={variant.id}>
              <div className="product-items__image">
                <VariantAnchor variant={variant} category={category}>
                  <div className="img-co img-co--260to350 img-co--img-contain">
                    <FlippingImages images={images} width={260} height={350} />
                    <SaveBadge shop={shop} variant={variant} currencies={currencies} />
                  </div>
                </VariantAnchor>
              </div>
              <div className="product-items__title">
                <VariantAnchor className="default-blue-link" variant={variant} category={category}>
                  {product.title}
                </VariantAnchor>
              </div>
              <div className="product-items__price">
                <VariantPrice shop={shop} variant={variant} currencies={currencies} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
