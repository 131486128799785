import Helmet from "react-helmet";
import React from "react";
import { BestSellers } from "components/BestSellers";
import { CategoryHighlight } from "components/CategoryHighlight";
import { Footer } from "components/Footer";
import { FooterMenu } from "components/FooterMenu";
import { Layout } from "components/Layout";
import { LazyImage } from "components/LazyImage";
import { Link } from "gatsby";
import { useSiteData } from "utils/useSiteData";

import { T } from "./Index.tlocale";

const Banner1 = () => (
  <div className="banner-with-image">
    <div className="banner-with-image__image">
      <div className="banner-image">
        <div className="img-co img-co--img-cover img-co--475x475-desktop img-co--1to1-mobile">
          <LazyImage
            className="img"
            src={require("images/9.jpg").default}
            fallback={require("images/9.jpg.fallback.json")}
          />
        </div>
        <div className="banner-image__background banner-image__background--yellow"></div>
      </div>
    </div>
    <div className="banner-with-image__content">
      <div className="banner-with-image__header banner-header">{T("We believe beauty starts from within")}</div>
      <div className="banner-with-image__description banner-description">
        {T("Australian beauty.")}
        <br />
        {T("100% Vegan, organic, cruelty free.")}
        <br />
        {T("Sensitive skin friendly.")}
      </div>
      <Link className="banner-with-image__button banner-button" to="/collection/sets-and-kits">
        {T("Shop Now")}
      </Link>
    </div>
  </div>
);

const Banner2 = () => (
  <div className="banner-with-image">
    <div className="banner-with-image__content">
      <div className="banner-with-image__header banner-header">{T("Wash the day away with our cleansers")}</div>
      <div className="banner-with-image__description banner-description">
        {T(
          "Our range of fabulously natural cleansers remove make up and removes impurity, to refresh and prepare your skin for hydration and treatment serums. Leaving your skin gloriously fresh for your beauty sleep or day ahead."
        )}
      </div>
      <Link className="banner-with-image__button banner-button" to="/collection/cleansers">
        {T("Shop Cleansers")}
      </Link>
    </div>
    <div className="banner-with-image__image">
      <div className="banner-image">
        <div className="img-co img-co--img-cover img-co--475x475-desktop img-co--1to1-mobile">
          <LazyImage
            className="img"
            src={require("images/8.jpg").default}
            fallback={require("images/8.jpg.fallback.json")}
          />
        </div>
        <div className="banner-image__background banner-image__background--red"></div>
      </div>
    </div>
  </div>
);

const BigQuote = () => (
  <div className="page-centered page-centered--550 page-centered--padding-bottom">
    <div className="big-quote">
      {T(
        "“Australian beauty is starting to trend for it’s use of natural yet affective ingredients inspired by nature.”"
      )}
    </div>
  </div>
);

const SocialLinks = () => (
  <div className="page-centered page-centered--650 page-centered--padding-bottom">
    <div className="link-images">
      <img src={require("images/sundaylife.jpg").default} width="209" height="56" />
      <img src={require("images/popsugar.svg").default} width="371" height="31" />
      <img src={require("images/smh.svg").default} width="293" height="30" />
      <img src={require("images/age.svg").default} width="247" height="26" />
    </div>
  </div>
);

export default () => {
  const { shop } = useSiteData();
  return (
    <Layout>
      <Helmet defer={false}>
        <title>{shop.name}</title>
      </Helmet>
      <div className="page-with-menu">
        <div className="page-with-menu__content">
          <Banner1 />
          <Banner2 />
          <BestSellers />
        </div>
        <div className="page-with-menu__content page-with-menu__content--no-padding page-with-menu__content--no-max-width">
          <CategoryHighlight />
          <BigQuote />
          <SocialLinks />
          <FooterMenu />
          <Footer />
        </div>
      </div>
    </Layout>
  );
};
